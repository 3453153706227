<template>
  <div class="container p-l-40">
    <div class="p-t-20">
      <div class="soltWrap">
        <div class="search">
          <div class="searchInput el-input-group">
            <div class="el-input-group__prepend">截止如期</div>
            <el-date-picker
              v-model="searchQuery.date"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="开始月份"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
    </div>
    <div class="financial-info">
      <div class="fi-item flex p-t-b-8">
        <div class="fii-title">会员余额：</div>
        <div class="fii-info flex align-center">
          截止查询余额统计：
          <span style="color: rgb(139, 139, 139); border: none; margin: 0 8px"
            >{{ balanceData.money }}
          </span>
          元
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
export default {
  //引入组件
  components: {},
  data() {
    const self = this;
    return {
      number: "",
      balanceData: {
        money: "0.00",
      },
      searchQuery: { date: self.$test.formatDate(new Date(), 1) },
    };
  },
  ///////////////////////////////////////////////////////////////////////////////////////////
  //计算机属性
  ///////////////////////////////////////////////////////////////////////////////////////////
  computed: {},
  ///////////////////////////////////////////////////////////////////////////////////////////
  //监听页面加载，其参数为上个页面传递的数据，参数类型为Object（用于页面传参）
  ///////////////////////////////////////////////////////////////////////////////////////////
  created() {
    this.getMoneyTotal();
  },
  watch: {
    searchQuery: {
      handler() {
        this.getMoneyTotal();
      },
      deep: true, //开启深度监听
    },
  },
  ///////////////////////////////////////////////////////////////////////////////////////////
  //方法调用
  ///////////////////////////////////////////////////////////////////////////////////////////
  methods: {
    // 获取设置详情
    async getMoneyTotal() {
      const { searchQuery } = this;
      const data = {
        date: searchQuery.date,
      };
      console.log(data);
      let result = await api.common.get({
        apiName: "/vip/userEveryDayMoney/moneyTotal",
        ...data,
      });
      this.balanceData = result.data;
    },
  },
};
</script>

<style scoped lang="scss">
.searchInput {
  width: 500px;
}
.financial-info {
  padding-top: 30px;
  margin: 0;
  font-size: 13px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  color: #434343;
}

.financial-info .fi-item {
  padding: 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.financial-info .fi-item .fii-title {
  width: 125px;
  color: #434343;
  font-size: 13px;
  background: #eee;
  border-radius: 19px;
  text-align: left;
  padding: 10px 0 10px 15px;
  margin-right: 20px;
}

.fii-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.fii-info span {
  padding-right: 40px;
  border-right: 1px solid #a0a0a0;
  margin-left: 20px;
  margin-right: 20px;
}

.fii-info span:last-child {
  border: none;
}
</style>
